<template>
<div class="customer__form modal modal--default">
    <div class="modal__panel">
        <div class="modal__logo-wrapper">
            <div class="modal__logo"></div>
            <div class="modal__logo-title">The One System</div>
        </div>

        <div class="modal__main-icon modal__main-icon--password"></div>
        <div class="modal__close" @click="closeModal()"></div>

        <div class="form">
            <div class="navigators">
                <div class="navigator navigator--active">
                    <div class="navigator__bullet"></div>
                     <div class="navigator__title">{{ $t(`changePasswordModal.${"title"}`) }}</div>  
                </div>
            </div>
            <div class="add__form ">
                <transition name="fade" mode="out-in">

                    <div class="add__content">
                        <div class="form__field">
                            <input type="password" class="input input--default" :class="{ 'input--error': currentPassHasError }" :placeholder="$t(`changePasswordModal.${'passPlaceholder'}`)"  v-model="currentPass" />
                            <div class="form__error" v-if="currentPassHasError">
                                {{ $t(`changePasswordModal.${"requiredMsg"}`) }}
                            </div>
                        </div>

                        <div class="form__field">
                            <input type="password" class="input input--default" :class="{ 'input--error': newPassHasError || newPassLengthError }" :placeholder="$t(`changePasswordModal.${'newPassPlaceholder'}`)" v-model="newPass" />
                            <div class="form__error" v-if="newPassHasError">
                                {{ $t(`changePasswordModal.${"requiredMsg"}`) }}
                            </div><br>
                            <div class="form__error" v-if="newPassLengthError"> 
                                {{ $t(`changePasswordModal.${"lengthMsg"}`) }}
                            </div>
                            
                        </div>

                        <div class="form__field">
                            <input type="password" class="input input--default" :class="{ 'input--error': confirmPassHasError || confirmPassMismatchError }" :placeholder="$t(`changePasswordModal.${'confirmPassPlaceholder'}`)" v-model="confirmPass" />
                            <div class="form__error" v-if="confirmPassHasError"> 
                                {{ $t(`changePasswordModal.${"requiredMsg"}`) }}
                            </div><br>
                            <div class="form__error" v-if="confirmPassMismatchError"> 
                                {{ $t(`changePasswordModal.${"mismatchMsg"}`) }}
                            </div>
                        </div>

                    </div>
                </transition>
            </div>
        </div>
        <div v-if="isRequesting" class="modal__button modal__button--submit button button--default button--loading"></div>
        <button v-else class="modal__button modal__button--submit button button--default" @click="updatePassword()">
            <span>{{ $t(`changePasswordModal.${"saveBtn"}`) }}</span>
        </button>
    </div>
</div>
</template>

<script> 
export default {
    name: "ChangePassword",

    components: {},

    data() {
        return { 
            isRequesting: false,

            currentPass: '',
            newPass: '',
            confirmPass: '',

            currentPassHasError: false,
            newPassHasError: false,
            confirmPassHasError: false, 
            newPassLengthError: false,  
            confirmPassMismatchError: false
        };
    },

    watch: {
        currentPass() {
            this.validateCurrentPass();
        },

        newPass() {
            this.validateNewPass();
        },

        confirmPass() {
            this.validateConfirmPass();
        },
    },

  
    destroyed() {
        this.$store.commit("setShowChangePasswordModal", false);
    },

    methods: {
        closeModal() {
            this.$store.commit("setShowChangePasswordModal", false);
        },

        validateCurrentPass() {
            if (this.currentPass) {
                this.currentPassHasError = false;
            } else {
                this.currentPassHasError = true;
            }
        },

        validateNewPass() {
            if (this.newPass) {
                if (this.newPass.length < 8) {
                    this.newPassHasError = false;
                    this.newPassLengthError = true;
                }  else{
                    this.newPassHasError = false;
                    this.newPassLengthError = false;
                }
            } else {
                this.newPassHasError = true;
                this.newPassLengthError = false;  
            }
        },

        validateConfirmPass() {
            if (this.confirmPass) {
                if (this.confirmPass !== this.newPass) {
                    this.confirmPassHasError = false;
                    this.confirmPassMismatchError = true;
                } else {
                    this.confirmPassHasError = false;
                    this.confirmPassMismatchError = false;
                }
            } else {
                this.confirmPassHasError = true;
                this.confirmPassMismatchError = false;  
            }
        },

        async updatePassword() { 
            this.validateCurrentPass();
            this.validateNewPass();
            this.validateConfirmPass();

            if (
                !this.currentPassHasError &&
                !this.newPassHasError &&
                !this.confirmPassHasError
            ) {
                this.isRequesting = true;
                try {
                    await this.$store.dispatch("updatePassword", {
                        current_password: this.currentPass,
                        password: this.newPass,
                        password_confirmation: this.confirmPass
                    });
                    this.$store.commit("setToast", {
                        display: true,
                        type: "success",
                        message: "Password updated successfully.",
                    });
                    this.isRequesting = false;
                    this.$store.commit("setShowChangePasswordModal", false);
                } catch (err) { 
                    let errorMessages = "Unable to update password.";
                
                    if (err.response.data.errors) {
                        errorMessages = Object.values(err.response.data.errors).flat();
                    } else if (err.response.data.message) {
                        errorMessages = [err.response.data.message];
                    }
            
                    errorMessages.forEach((msg) => {
                        this.$store.commit("setToast", {
                            display: true,
                            type: "error",
                            message: msg,
                        });
                    }); 
                    this.isRequesting = false; 
                } 
            }
        },

    },
};
</script>

<style lang="scss" scoped>
 
.form__field{
    width: 40%;
    margin:  30px auto;
}

.add__form{
    width: 100% !important;
}
 
</style>
